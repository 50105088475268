<template>
  <b-container fluid>
    <ResourceList
      can-delete create-text="Marketing Cost" create-to="dash.marketing-costs.create" resource="marketing cost" soft-deletes title="Marketing Costs"
      :data="data" :is-deleting="isDeleting" :is-loading="isLoading" :pagination="pagination"
      :search-query="$route.query.query" @delete-many="onDeleteMany" @paginate="onPaginate"
      @search="onSearch">

      <template #buttons>
        <b-dd-item-button @click="importModalOpen = true">Import Marketing Costs</b-dd-item-button>
      </template>

      <template #listItem="{ item }">
        <div class="d-flex flex-column flex-fill">
          <div class="d-flex flex-row flex-fill align-items-center justify-content-center">
            <fa-icon class="mx-1" :icon="returnCostIcon(item)"></fa-icon>
            <p class="h5 flex-fill mb-0 text-primary" v-if="item.manual"><router-link class="text-dark" :to="{name: 'dash.marketing-costs.update', params: { id: item.id }}">{{ item.name }}</router-link></p>
            <p class="h5 flex-fill mb-0 text-dark" v-else>{{ item.name }}</p>
            <div>
              <p class="align-self-end mb-1 text-muted">{{ moment(item.date).format('DD/MM/YYYY') }}</p>
            </div>
          </div>
          <div class="d-flex flex-row flex-fill">
            <p class="mb-0 text-muted">{{ item.amount | currencyFormat }}</p>
          </div>
        </div>
      </template>

    </ResourceList>
    <b-modal id="import-modal" title="Import Marketing Data" size="lg" centered v-model="importModalOpen">
      <template #modal-header="{ close }">
        <div class="d-flex align-items-center flex-row w-100">
          <div class="flex-column flex-fill">
            <h5 class="mb-0 flex-fill">Import Marketing Data</h5>
            <small class="text-muted">When importing the file for upload, please ensure that the file is converted to a .csv file.</small>
          </div>
          <b-button @click="close()" variant="outline-secondary" class="align-self-start"><fa-icon icon="times"/></b-button>
        </div>
      </template>
      <b-row>
        <b-col cols="12">
          <b-form-group label="File Import">
            <b-form-file
              v-model="importFile"
              accept=".csv"
              :state="Boolean(importFile)"
              :placeholder="Boolean(importFile) ? importFile.name : 'Select a file to upload'"
              drop-placeholder="Drop a file here..."
            ></b-form-file>
          </b-form-group>
        </b-col>
      </b-row>
      <template #modal-footer>
        <b-button :variant="importButtonClass" @click="doImportCSV">
          <span v-if="!importInProgress && importProgressStatus == null">Upload CSV</span>
          <b-spinner v-if="importInProgress"></b-spinner>
          <fa-icon v-if="importProgressStatus != null" :icon="importProgressStatus ? 'check' : 'times'"></fa-icon>
        </b-button>
      </template>
    </b-modal>
  </b-container>
</template>

<script>
import FriendlyDate from '../../components/FriendlyDate'
import ResourceList from '../../components/ResourceList'
import currentUser from '../../mixins/currentUser'
import momentMixin from '../../mixins/momentMixin'
import numberMixin from '../../mixins/numberMixin'
import { mapActions, mapGetters } from 'vuex'

export default {
  mixins: [currentUser, momentMixin, numberMixin],
  created () {
    this.fetchMany(this.$route.query)
  },
  data: () => ({
    importModalOpen: false,
    importFile: null,

    importInProgress: false,
    importProgressStatus: null,
  }),
  components: {
    FriendlyDate,
    ResourceList
  },
  computed: {
    ...mapGetters('marketing-costs', ['data', 'isDeleting', 'isLoading', 'pagination']),
    importButtonClass() {
      switch(this.importProgressStatus) {
        case true: return 'primary'; break;
        case false: return 'danger'; break;
        default: return 'primary';
      }
    }
  },
  methods: {
    ...mapActions('marketing-costs', ['deleteMany', 'fetchMany']),
    async onDeleteMany ({ ids, modalId }) {
      await this.deleteMany({ ids })
      this.$bvModal.hide(modalId)
      await this.fetchMany(this.$route.query)
    },
    async onPaginate (page) {
      if (this.$route.query.page !== page) {
        this.$router.push({ name: this.$route.name, query: { ...this.$route.query, page } })
        await this.fetchMany({ ...this.$route.query, page })
      }
    },
    async onSearch (query) {
      if (this.$route.query.query !== query) {
        this.$router.push({ name: this.$route.name, query: { ...this.$route.query, query } })
        await this.fetchMany({ ...this.$route.query, query })
      }
    },

    returnCostIcon(item) {
      switch(item.platform) {
        case 'Facebook': return { prefix: 'fab', iconName: 'facebook' }; break;
        case 'Snapchat': return { prefix: 'fab', iconName: 'snapchat' }; break;
        case 'Google': return { prefix: 'fab', iconName: 'google' }; break;
        case 'Pinterest': return { prefix: 'fab', iconName: 'pinterest' }; break;
        case 'Tiktok': return { prefix: 'fab', iconName: 'tiktok' }; break;
        default: return 'circle';
      }
    },

    doImportCSV() {
      this.importInProgress = true
      if(Boolean(this.importFile)) {
        let formData = new FormData()
        formData.append('csv', this.importFile)
        axios.post('/marketing-costs/import', formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        }).then(response => {
          this.importInProgress = false
          this.importProgressStatus = false
          if(response.data) {
            this.importProgressStatus = true
          }
          
          setTimeout(() => {
            this.importProgressStatus = null
          }, 750)
        }).catch(err => {
          this.importInProgress = false
          this.importProgressStatus = false
          
          setTimeout(() => {
            this.importProgressStatus = null
          }, 750)
        })
      }
    }
  }
}
</script>

<style>

</style>
