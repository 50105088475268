<template>
  <div>
    <b-row class="mb-2">
      <b-col class="mb-2 mb-md-0" cols="12" :md="!(hasSearch || hasButtons) ? 12 : 4">
        <h1 class="font-weight-bold mb-0 text-primary">{{ title }}</h1>
        <p class="mb-2"><small><b-icon icon="question-circle" v-if="isLoading" />{{ isLoading ? '' : pagination.total }} {{ pagination.total === 1 ? resource : resourcePlural }} found</small></p>
      </b-col>
      <b-col class="d-md-none mb-2" cols="12" v-if="hasSearch">
        <b-input class="mr-2" placeholder="Search..." @update="onSearch"></b-input>
      </b-col>
      <b-col class="d-flex flex-row align-items-center justify-content-start justify-content-md-end" cols="12" md="8" v-if="hasSearch">
        <b-input class="mr-2 d-none d-md-block" placeholder="Search..." @update="onSearch"></b-input>
        <b-button class="mr-2 text-nowrap" variant="secondary" :to="{name: createTo}" v-if="canCreate && !selected.length">Create {{ createText ? createText : resourceNameCasing('camel', resource) }}</b-button>
        <b-button class="mr-2 text-nowrap" :disabled="!data.length" variant="dark" @click.prevent="selectAll" v-if="selected.length"><span v-if="selected.length < data.length || !data.length">Select All</span><span v-if="selected.length === data.length && data.length">Deselect All</span></b-button>
        <b-button class="mr-2 text-nowrap" variant="danger" v-b-modal.delete-many-resources v-if="canDelete && canSelectMany && selected.length">Delete {{ resourceNameCasing('camel', resourcePluralIf(selected)) }}</b-button>
        <b-dropdown class="ml-auto ml-md-0" no-caret right variant="dark" v-if="hasButtons">
          <template #button-content>
            <b-icon font-scale="1.2" icon="three-dots-vertical"></b-icon>
          </template>

          <b-dd-item variant="secondary" v-if="canCreate && selected.length" :to="{name: createTo}">Create {{ resourceNameCasing('camel', resource) }}</b-dd-item>
          <b-dd-divider v-if="selected.length" />
          <b-dd-item-button @click.prevent="selectAll" :disabled="!data.length" v-if="canSelectMany && !selected.length"><span v-if="selected.length < data.length || !data.length">Select All</span><span v-if="selected.length === data.length && data.length">Deselect All</span></b-dd-item-button>
          <slot name="buttons"></slot>
          <!-- <b-dd-divider v-if="canDelete && canSelectMany && !selected.length" />
          <b-dd-item-button variant="danger" v-if="canDelete && canSelectMany && !selected.length">Delete {{ resourceNameCasing('camel', resourcePluralIf(selected)) }}</b-dd-item-button> -->
        </b-dropdown>
      </b-col>
      <b-col class="d-flex flex-row align-items-center justify-content-start justify-content-md-end" cols="12" md="8" v-if="hasButtons && !hasSearch">
        <slot name="buttons"></slot>
      </b-col>
    </b-row>
    <b-row class="mb-4" v-if="hasFilters">
      <b-col cols="12" v-if="collapsableFilters">
        <b-card>
          <b-row>
            <b-col class="d-flex flex-row" cols="12" @click.prevent="toggleCollapse('filters')" role="button">
              <b-icon class="align-self-center cursor-pointer text-muted" font-scale="1.5" :icon="collapsed['filters'] ? 'chevron-right' : 'chevron-down'"></b-icon>
              <div class="d-flex flex-column flex-fill ml-4">
                <p class="h4 font-weight-bold mb-0">Filters</p>
              </div>
            </b-col>
          </b-row>
          <b-row class="mt-4" v-if="!collapsed['filters']">
            <b-col cols="12">
              <slot name="filters" />
            </b-col>
          </b-row>
        </b-card>
      </b-col>
      <b-col cols="12" v-if="!collapsableFilters">
        <slot name="filters" />
      </b-col>
    </b-row>
    <b-row class="mt-5 mb-2" v-if="isLoading">
      <b-col class="text-center" cols="12">
        <b-spinner variant="secondary"></b-spinner>
      </b-col>
    </b-row>
    <b-row class="mb-2" v-if="!data.length && !isLoading && !hideList">
      <b-col class="text-center" cols="12">
        <b-card class="py-5 text-center">
          <p class="mb-4"><b-icon class="display-1 text-dark" icon="cloud-arrow-down"></b-icon></p>
          <p class="font-weight-normal h5 mb-0 text-dark">Could not find any {{ resourcePlural }} that match the given criteria.</p>
        </b-card>
      </b-col>
    </b-row>
    <b-row class="mb-2" v-if="data && !isLoading && !hideList">
      <b-col cols="12">
        <b-list-group>
          <b-list-group-item :key="item.id" v-for="item in data" :class="{'p-0': !canSelectMany}">
            <div class="d-flex flex-row justify-items-start">
              <div class="align-self-center mr-2" v-if="canSelectMany">
                <b-checkbox :value="item.id" v-model="selected"></b-checkbox>
              </div>
              <slot name="listItem" :item="item" />
            </div>
          </b-list-group-item>
        </b-list-group>
      </b-col>
    </b-row>
    <b-row class="mb-2" v-if="data && !isLoading && pagination.per < pagination.total">
      <b-col cols="12">
        <b-pagination @change="onPaginate" :per-page="pagination.per" :total-rows="pagination.total" v-model="pagination.current"></b-pagination>
      </b-col>
    </b-row>
    <b-modal centered id="delete-many-resources" :title="`Delete ${this.selected.length} ${resourcePluralIf(this.selected)}?`"
      ok-variant="danger" cancel-variant="dark" ok-title="Delete" cancel-title="Cancel" @ok.prevent="onDeleteModalOk"
      :ok-disabled="isDeleting" :cancel-disabled="isDeleting">
      <p class="text-center"><b-spinner class="my-2" variant="danger" v-if="isDeleting"/></p>
      <p v-if="!isDeleting && !softDeletes">This action is destructive, which means the data will be permanently deleted.</p>
      <p v-if="!isDeleting && softDeletes">They will be marked as "trashed".</p>
    </b-modal>
  </div>
</template>

<script>
/* eslint-disable */
export default {
  data () {
    return {
      selected: [],

      collapsed: {
        filters: true,
      },
    }
  },
  props: {
    canCreate: {
      default: true,
      type: Boolean
    },
    canDelete: {
      default: false,
      type: Boolean
    },
    canSelectMany: {
      default: true,
      type: Boolean
    },
    createTo: {
      default: '',
      type: String
    },
    data: {
      required: true,
      type: Array
    },
    hasFilters: {
      default: false,
      type: Boolean
    },
    collapsableFilters: {
      default: true,
      type: Boolean
    },
    hasButtons: {
      default: true,
      type: Boolean
    },
    isDeleting: {
      default: false,
      type: Boolean
    },
    isLoading: {
      default: false,
      type: Boolean
    },
    pagination: {
      required: true,
      type: Object
    },
    resource: {
      required: true,
      type: String
    },
    resourcePlural: {
      default () { return this.resource.toLowerCase() + 's' },
      type: String
    },
    softDeletes: {
      default: false,
      type: Boolean
    },
    hasSearch: {
      default: true,
      type: Boolean
    },
    searchQuery: {
      default: '',
      type: String
    },
    hideList: {
      default: false,
      type: Boolean
    },
    title: {
      required: true,
      type: String
    },
    createText: {
      required: false,
      type: String
    }
  },
  methods: {
    onDeleteModalOk (bvModalEvt) {
      this.$emit('delete-many', { ids: this.selected, modalId: bvModalEvt.target.id })
    },
    onPaginate (pageNo) {
      this.$emit('paginate', pageNo)
    },
    onSearch (query) {
      this.onPaginate(1)
      this.$emit('search', query)
    },

    toggleCollapse (name) {
      this.collapsed[name] = !this.collapsed[name]
    },

    resourceNameCasing (casing, resourceName) {
      if (!resourceName) {
        return ''
      }

      switch (casing) {
        case 'camel':
          return resourceName.charAt(0).toUpperCase() + resourceName.slice(1)
        case 'upper':
          return resourceName.toUpperCase()
        case 'lower':
          return resourceName.toLowerCase()
      }
    },
    resourcePluralIf (arr) {
      return arr.length === 1 ? this.resource : this.resourcePlural
    },
    selectAll () {
      if (this.selected.length === this.data.length) {
        this.selected = []
      } else {
        this.selected = this.data.map(d => d.id)
      }
    }
  },
  watch: {
    data () {
      this.selected = []
    }
  }
}
</script>

<style>

</style>
